@import url("https://fonts.googleapis.com/css2?family=Cormorant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo&display=swap");

.sideBar {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: white;
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  background-color: #fffffffc;
}

.sideBar button {
  float: right;
  margin: 10px;
}

.company {
  font-size: 28px;
  font-family: "Cormorant", "San-Serif";
  margin-bottom: 45px;
  background-color: grey;
  padding: 10px;
  text-align: center;
}


.options {
  font-family: "Cardo", "san-serif";
}

.options div {
  margin: 20px;
  cursor: pointer;
}
.options a {
  color: #000;
  text-decoration: none;
}
.options a:visited {
  color: #000;
}

.options div:hover {
  color: rgb(83, 77, 77);
}

@media (min-width: 768px) {
  .sideBar {
    display: none;
  }
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-100%);
}
