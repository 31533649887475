.portfolio {
  width: 70vw;
  padding: 45px 0;
  margin: 0 15vw;
  text-align: left;
}
.heading {
  font-family: Georgia, sans-serif;
  margin-top: 15px;
  margin-right: 40px;
  padding: 10px;
}
.heading h4 {
  letter-spacing: 4px;
}

@media only screen and (max-width: 768px) {
  .portfolio {
    width: 90vw;
    margin: 0 5vw;
    justify-content: center;
  }
  .heading {
    padding: 10px 0px;
  }
}
