@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo&display=swap");

.Main {
  width: 70vw;
  padding-top: 100px;
  margin: 0 15vw;
  display: flex;
  align-items: center;
}

.Logo {
  border-top: 1px solid black;
  /* padding-right: 20%; */
  max-height: 250px;
  max-width: 250px;
}
.Logo img {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  margin: auto;
}
.Quote {
  padding: 0 50px;
  width: 100%;
}
.Quote p {
  color: #414040;
  font-size: 20px;
  font-family: "Cardo", sans-serif;
  text-align: justify;
  margin-bottom: 20px;
  word-spacing: 4px;
  line-height: 1.3;
}

@media only screen and (max-width: 768px) {
  .Main {
    margin: 0 5vw;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
  }
  .Logo {
    display: flex;

    max-height: 300px;
    max-width: 300px;

    width: 500px;
    height: 500px;
    /* padding-right: 30%; */
  }
  .Quote {
    padding: 0 5vw;
    width: 100%;
  }
  .Quote p {
    margin-top: 20px;
    color: #414040;
    font-size: 18px;
    font-family: "Cardo", sans-serif;
    text-align: left;
  }
}
