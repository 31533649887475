.stats {
  padding: 60px 0px 100px;
  margin: 0 15vw;
  display: flex;
  justify-content: center;
  
}

.imageSlider {
  display: flex;
  align-items: center;
  max-width: 323px;
  min-height: 517px;
}

@media only screen and (max-width: 990px) {
  .stats {
    display: flex;
    flex-direction: column;
    margin: 0 5vw;
    padding-bottom: 50px;
  }
  .card span {
    font-size: 28px;
  }
}
