@import url("https://fonts.googleapis.com/css2?family=Cormorant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo&display=swap");
.Background {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/Images/background_main.jpg");
  position: relative;
  z-index: 1;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container*/
}

.Background::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255,0.3);
}

.Middle {
  width: 70vw;
  height: 100vh;
  position: absolute;
  margin: 0 15vw;
  display: flex;
  flex-direction: column;
}
.Navbar {
  padding: 5vh 0;
  font-family: "Cardo", "Serif";
  font-size: 14px;
  font-weight: 550;
}
.List {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}
.Logo {
  padding: 2vw;
  height: 90px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.Logo img {
  max-height: 200px;
  flex-shrink: 0;
}
.List ul {
  align-items: center;
  justify-content: space-between;
  display: inline-flex;
  overflow: hidden;
  padding: 10px 5%;
}
.List a {
  margin: 0 20px;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.List a:hover {
  color: rgb(83, 77, 77);
}
.List a:visited {
  color: black;
}
.Quote {
  margin-top: 18vh;
  /* background-color: grey; */
  height: 25%;
  padding-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Quote span {
  font-size: 38px;
  font-family: "Cormorant", "San-Serif";
  margin-bottom: 20px;
}
.Quote strong {
  font-size: 38px;
  font-family: "Cormorant", "San-Serif";
  margin-bottom: 20px;
}

.Quote button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2%;
  background: black;
  color: white;
  padding: 1%;
  width: 35%;
  height: 30%;
  border: 1px bold black;
  border-radius: 10px;
  cursor: pointer;
  font-size: 17px;
  font-family: "Cardo", "san-serif";
}

.Quote button:hover {
  opacity: 0.7;
}

.Button a {
  color: white;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}
.Button img {
  height: 30px;
  width: 30px;
}

@media only screen and (max-width: 768px) {

  .Background {
    width: 100vw;
    height: 70vh;
    background-image: url("../assets/Images/background_main.jpg");
  }
  .burger{
    display: flex;
    margin-top: 30px;
  }

  .Navbar {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #e5e5f7;
   margin:2vh 20vw;
   background: rgb(195,195,195);
   box-shadow: -3px -5px 21px 1px rgba(0,0,0,0.75);

 }
  .Middle {
    width: 90%;
    height: 70vh;
    margin: 0% 5%;
  }
  .List ul {
    display: none;
  }
  .Quote {
    margin: 6vh 0;
    height: 25%;
    padding-top: 3%;
  }
  .Quote span {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .Quote strong {
    font-size: 24px;
    font-family: "Cormorant", "San-Serif";
    margin-bottom: 15px;
  }

  .Quote button {
    margin-top: 4vh;
    font-size: 13px;
    width: 240px;
    height: 60px;
    padding: 15px 30px;
  }
  .Button img {
    height: 20px;
    width: 20px;
    padding: 5px;
  }
}

