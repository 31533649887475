.privacy {
  width: 100%;
  /* height: 100px; */
  padding-top: 60px;
  background-color: #414141;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
}

.privacy span {
  font-family: Georgia, sans-serif;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 3px;
  padding: 0 30px;
  height: 36px;
  cursor: pointer;
}

.logo {
  width: 100%;
  background-color: #414141;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 40px;
}

.copy {
  background-color: #1a1a1a;
  color: #ffffff;
  height: 83px;
}

.copy h5 {
  padding: 10px;
  font-size: 14px;
  font-family: Georgia, sans-serif;
  letter-spacing: 2px;
  font-weight: 400;
}


@media only screen and (max-width: 990px) {
  .privacy span {
    font-size: 10px;
  }
}
