@import url("https://fonts.googleapis.com/css2?family=Cardo&display=swap");

.whyus {
  width: 70vw;
  padding-top: 100px;
  margin: 0 15vw;
  display: flex;
  display: flex;
}
.heading {
  width: 40%;
  height: 140px;
  text-align: left;
  align-items: center;
  justify-content: center;
  border-top: 1px solid black;
}
.heading h4 {
  margin-top: 40px;
  font-size: 40px;
  font-family: "Georgia", Sans-serif;
  font-weight: 200;
  letter-spacing: 3px;
}
.para {
  width: 100%;
  padding: 5px 50px 0px;
}
.para p {
  font-size: 20px;
  color: #414040;
  font-family: "Cardo", sans-serif;
  text-align: justify;
  margin-bottom: 20px;
  word-spacing: 4px;
  line-height: 1.7;
}

@media only screen and (max-width: 768px) {
  .whyus {
    margin: 0 5vw;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
  }
  .heading {
    width: 90vw;
    margin: 0;
  }

  .para {
    padding: 0;
    width: 100%;
  }
  .para p {
    margin-top: 20px;
    color: #414040;
    font-size: 18px;
    font-family: "Cardo", sans-serif;
    text-align: left;
  }
}
