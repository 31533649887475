@import url("https://fonts.googleapis.com/css2?family=Cormorant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo&display=swap");
.container {
  position: relative;
  overflow: hidden !important;
  font-family: "Cormorant", "Times New Roman", Times, serif;
  
}

.image {
  
  width: 100%;
  height: auto;
  opacity: 0.8;

}

.overlay {
 
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease-in-out;
  color: white;
  background-color: rgba(70, 70, 70, 0.7);
  
}

.left {
  transform: translateX(-400px);
}

.right {
  transform: translateX(400px);
}

.top {
  transform: translateY(-500px);
}

.bottom {
  transform: translateY(500px);
}

.container:hover .overlay {
  opacity: 0;
  transform: translateX(0px);
  transform: translateY(0px);
  cursor: pointer;
}

.text {
  color: white;
  position: absolute;
  top: 25%;
  padding: 0 50px;
  justify-content: center;
  text-align: left;
  font-style: Cormorant;
}
