@import url("https://fonts.googleapis.com/css2?family=Cormorant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo&display=swap");

.Display{
    padding-top: 50px;
    background-color: #212121;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Display h2{
    padding-top: 50px;
    padding-bottom: 100px;
    font-family: Cardo, 'Times New Roman', Times, serif;
    color:white;
    letter-spacing: 3px;
    text-decoration: underline grey 10px;

}
.Logo {
    padding: 50px;
    background-color: rgba(255, 255, 255, 0.9); 
    height: 90px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
  }

  .Logo img {
    max-height: 200px;
    flex-shrink: 0;
  }