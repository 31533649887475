@import url("https://fonts.googleapis.com/css2?family=Cardo&display=swap");

.whyus {
  width: 70vw;
  padding-top: 100px;
  margin: 0 15vw;
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;
}
.heading {
  width: 40%;
  height: 140px;
  text-align: left;
  align-items: center;
  justify-content: center;
  border-top: 1px solid black;
}
.heading h4 {
  margin-top: 40px;
  font-size: 40px;
  font-family: "Georgia", Sans-serif;
  font-weight: 200;
  letter-spacing: 3px;
}
.para {
  width: 100%;
  padding: 5px 50px 0px;
}
.para p {
  font-size: 20px;
  color: #414040;
  font-family: "Cardo", sans-serif;
  text-align: justify;
  margin-bottom: 20px;
  word-spacing: 4px;
  line-height: 1.7;
}
/* yaha seh */
.box {
  background: rgb(65,65,65);
background: linear-gradient(48deg, rgba(65,65,65,1) 0%, rgba(108,108,108,1) 45%, rgba(219,219,219,1) 100%);
  border-radius: 5px;
  color: white;
  padding: 0px;
  height: 305px;
  width: 350px;
  box-shadow: -1px 6px 32px -10px rgba(0, 0, 0, 0.75);
  text-align: center;
  transition: 0.3s;
  /* overflow: hidden; */
}
.box:hover {
  background: rgb(65,65,65);
background: linear-gradient(48deg, rgba(65,65,65,1) 0%, rgba(108,108,108,1) 45%, rgba(219,219,219,1) 100%);
  border-radius: 5px;
  color: white;
  height: 450px;
  width: 380px;
  box-shadow: -1px 6px 32px -10px rgba(0, 0, 0, 0.75);
  padding: 10px;
}

.img_box {
  height: 290px;
  width: 290px;
  top: -80px;
  object-fit: cover;
  border-radius: 6px;
  /* border: 1px solid black;  */
  box-shadow: -1px 6px 32px -10px rgba(0, 0, 0, 0.75);
  position: relative;
  background-color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.prof_container {
  margin: 10px;
  width: fit-content;
  padding-top: 80px;
}
.name {
  margin-top: -75px;
  font-size: 27px;
  /* font-size: x-large; */
  text-transform: uppercase;
}
.des {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 20px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}
.box h2 {
  font-family: "Cardo", sans-serif;
  letter-spacing: 2px;
}
.box h3 {
  font-family: "Cardo", sans-serif;
  letter-spacing: 2px;
}
.description {
  font-family: "Cardo", sans-serif;
  text-align: justify;
  margin-top: 10px;
}

.team {
  display: flex;
  justify-content: space-evenly;
}
@media only screen and (max-width: 768px) {
  .whyus {
    margin: 0 5vw;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    margin-bottom: 0px;
  }
  .heading {
    width: 90vw;
    margin: 0;
  }

  .para {
    padding: 0;
    width: 100%;
  }
  .para p {
    margin-top: 20px;
    color: #414040;
    font-size: 18px;
    font-family: "Cardo", sans-serif;
    text-align: left;
  }
  .team {
    flex-direction: column;
    align-items: center;
  }
  .box {
    margin-bottom: 100px;
    height: 450px;
    width: 380px;
  }
  .description {
    padding: 10px;
  }
}
