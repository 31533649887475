@import url("https://fonts.googleapis.com/css2?family=Cardo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant&display=swap");
.card {
  width: 360px;
  min-height: 517px;
  padding: 25px 25px;
  margin: 15px 0px 15px 15px;
  background-color: #dbdbdb;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: #414141;
  border-radius: 4px 4px 4px 4px;
}
.card span {
  font-size: 34px;
}

.card div {
  font-family: cormorant;
  font-size: 21px;
}
.card span {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 34px;
}
.card p {
  margin: 40px 0px;
  font-size: 14px;
  font-family: Cardo, "Times New Roman", Times, serif;
}
.para {
  padding: 20px;
  /* margin: -25px 0px 0px; */
}

@media only screen and (max-width: 990px) {
  .card {
    width: 80vw;
    min-height: 300px;
    margin: 20px 0px;
  }
  .card span {
    font-size: 28px;
  }
  .card p {
    margin: 20px 0px;
  }
}
