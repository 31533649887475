@import url("https://fonts.googleapis.com/css2?family=Cardo&display=swap");

.ImageGrid {
  padding-top: 100px;
  margin: 0 15vw;
  margin-bottom: 100px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 30px;
  height: 90%;
  justify-content: space-evenly;
  background-color: #f8f8f8;
  font-family: "Cardo", sans-serif;
}

.text h2 {
  font-size: 25px;
  color: #000000;
  letter-spacing: 2px;
  font-weight: 600;
}

.text span {
  font-size: 15px;
  color: #303030;
}



@media only screen and (max-width: 990px) {
    .ImageGrid {
        margin: 0 5vw;
        padding-top: 50px;
    }
  }
  