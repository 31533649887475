.Tphoto {
  padding: 50px 0px 100px 0px;
  margin: 0 10vw;
}

@media only screen and (max-width: 990px) {
  .Tphoto {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-bottom: 100px;
  }
}
