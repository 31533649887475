@import url("https://fonts.googleapis.com/css2?family=Cardo&display=swap");

.card {
  margin: 0px 8px;
  padding: 35px;
  height: 325px;
  width: 348px;
  text-align: left;
  box-shadow: 1px 1px 25px 0 rgba(0, 0, 0, 0.15);
}

.card:hover {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
}


@media only screen and (max-width: 990px) {
  .card {
    margin: 0px;

    height: 350px;
    width: 300px;
    text-align: left;
  }
}
