@import url("https://fonts.googleapis.com/css2?family=Cardo&display=swap");

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Logo {
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.9);
  height: 90px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Logo img {
  max-height: 200px;
  flex-shrink: 0;
}

.pp{
    background-color: rgb(78, 78, 78);
    color:white;
    margin:5vw 10vw;
    padding:10vh 0 5vh;
    border: 20px rgb(65, 65, 65) bold;

}
.pp h2 {
  font-size: 40px;
  font-family: "Cardo", sans-serif;
  letter-spacing: 2px;
  font-weight: 400;
}
.pp h4 {
  text-align: left;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 20px;
  letter-spacing: 1px;
}
.pp p {

  text-align: left;
  margin-left: 100px;
  margin-right: 100px;
  font-family: "Cardo", sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
  word-spacing: 2px;
}


/* CSS */

.button_85{

    display: inline-block;
    outline: 0;
    cursor: pointer;
    border: none;
    padding: 0 56px;
    height: 45px;
    line-height: 45px;
    border-radius: 7px;
    background-color: #383838;
    color: white;
    font-weight: 400;
    font-size: 16px;
    box-shadow: 0 4px 14px 0 rgb(0 118 255 / 39%);
    transition: background 0.2s ease,color 0.2s ease,box-shadow 0.2s ease;
   margin-bottom: 50px;
    
}



.button_85:hover{
    background: rgba(74, 75, 77, 0.9);
    box-shadow: 0 6px 20px rgb(0 118 255 / 23%);
}


@media only screen and (max-width: 768px) {
    .pp{
        
        margin:2.5vw 1vw;
        padding:4vh 0 2vh;
        border: 20px rgb(65, 65, 65) bold;
    
    }
    .pp h2{
margin-bottom: 40px;
    }
    
      .pp h4 {
        text-align: left;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 20px;
        letter-spacing: 1px;
      }
      .pp p {
      
        margin-left: 30px;
        margin-right: 30px;
        
      }
      
  }
  